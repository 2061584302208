/* 12.06 Work Details CSS */
.portfolio-main-info, .digital-marketing {
    .title{
        font-size: 42px;
        line-height: 1.3;
        margin-bottom: 0;

        //Responsive
        @media #{$tablet-device}{
            font-size: 36px;
        }
        @media #{$large-mobile}{
            font-size: 32px;
        }

    }
}
.work-details-list{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 62px 30px;
    .details-list{
        label{
            color: $heading-color;
            text-transform: capitalize;
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 12px;
        }
        span{
            color: $body-color;
            font-weight: 500;
            line-height: 1.5;
            font-size: 16px;
            display: block;
            a{
                color: $body-color;
                transition: $transition;
                &:hover{
                    color: $primary;
                }
            }
        }
    }
}


