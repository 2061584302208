/* Bootstrap Theming Variable CSS */

// Variable overrides
$body-bg: #fff; // Utilisez un fond blanc pour une meilleure lisibilité
$body-color: #748494; // Couleur neutre pour le texte
$heading-color: #333a7a; // Utilisez le violet comme couleur de mise en évidence pour les titres

$primary: #c7326e; // Rose comme couleur primaire
$primary-two: #ff85b2; // Un rose plus clair pour diversifier
$primary-three: #8a004b; // Orange léger pour des accents contrastés

$secondary: #333a7a; // Violet comme couleur secondaire
$secondary-light: #7a77b8; // Gris clair pour les fonds ou zones moins prioritaires
$secondary-three: #00003e; // Un rose-violet plus doux pour des accents subtils

$success: #67d68a; // Vert doux pour les succès
$danger: #F44336; // Rouge pour les alertes
$warning: #FFC107; // Jaune pour les avertissements
$info: #17A2B8; // Bleu pour les informations

$light: #F8F9FA; // Utilisé pour les arrière-plans très clairs
$off-white: #f6f2ed; // Crème doux pour diversifier les blancs
$white-gray: #e3e4e8; // Gris très clair
$white-light: #cacaca; // Gris clair
$white-opacity-50: rgba(255, 255, 255, 0.5); // Blanc avec transparence
$white: #fff; // Blanc pur

$overlay-black: #030f27; // Noir profond pour les superpositions
$light-orange: rgba(239, 111, 49, .1); // Orange léger pour les surbrillances ou arrière-plans

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $overlay-black,
        "white-gray": $white-gray,
        "white-opacity-50": $white-opacity-50,
        "white": $white,
        "overlay-black": $overlay-black,
);

// Color system
$gray-100: #748494;
$gray-200: #8C89A2;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #cacaca;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;


// fusv-disable
$grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
);

$grid-gutter-width: 30px;

$spacers: (
        0: 0,
        1: 5px,
        2: 10px,
        3: 15px,
        4: 20px,
        5: 25px,
        6: 30px,
        7: 35px,
        8: 40px,
        9: 45px,
        10: 50px,
        11: 55px,
        12: 60px,
        13: 65px,
        14: 70px,
        15: 75px,
        16: 80px,
        17: 85px,
        18: 90px,
        19: 95px,
        20: 100px,
        21: 105px,
        22: 110px,
        23: 115px,
        24: 120px,
        25: 125px,
        26: 130px,
        27: 135px,
        28: 140px,
        29: 145px,
        30: 150px,
        31: 155px,
        32: 160px,
        33: 165px,
        34: 170px,
        35: 175px,
        36: 180px,
        37: 185px,
        38: 190px,
        39: 195px,
        40: 200px,
        41: 205px,
        42: 210px,
        43: 215px,
        44: 220px,
        45: 225px,
        46: 230px,
        47: 235px,
        48: 240px,
        49: 245px,
        50: 250px,
);

$enable-negative-margins: true;

// Grid Responsive
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px
);

// Bootstrap and its default variables
// @import "node_modules/bootstrap/scss/bootstrap";
@import "./bootstrap/bootstrap";