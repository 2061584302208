/* 07.01. Page Title CSS */

.page-title-section {
    position: relative;
    height: 530px;
    display: flex;
    align-items: center;
    z-index: 9;
    flex-direction: column;
    justify-content: center;
    // Responsive
    @media #{$laptop-device}{
        height: 400px;
    }
    @media #{$desktop-device}{
        height: 400px;
    }
    @media #{$tablet-device}{
        height: 400px;
    }
    @media #{$large-mobile}{
        height: 350px;
    }
}

.page-title {
    & .title {
        font-size: 28px;
        line-height: 1.2;
        color: $white;
        margin: 0;
        text-align: center;
    }

    @media #{$desktop-device} {
        & .title {
            font-size: 42px;
        }
    }

    @media #{$tablet-device} {
        & .title {
            font-size: 34px;
        }
    }

    @media #{$large-mobile} {
        & .title {
            font-size: 23px;
            line-height: 1.5;
        }
    }
}
